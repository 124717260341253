import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Astrovan() {
  return (
    <Layout>
      <Helmet title="Astrovan | Mt. Joy" />

      <h1>Astrovan</h1>

      <h2>Mt. Joy</h2>

      <h4>Key: D</h4>

      <h4>Capo: 2</h4>

      <h4>Tempo: 84</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p><Ch>D</Ch>Angels smoking cigarettes on <Ch>Em</Ch>rooftops in fishnets in the morning with the</p>
          <p><Ch>D</Ch>Moon still <Ch>Em</Ch>glowing</p>
          <p>And <Ch>D</Ch>here comes Jesus in an Astrovan <Ch>Em</Ch>rolling down the strip again</p>
          <p>He's <Ch>D</Ch>stoned while Jerry <Ch>G</Ch>plays</p>
        </Verse>

        <Chorus>
          <p>He said <Ch>D</Ch>life ain't ever what it seems</p>
          <p>These <Ch>Em</Ch>dreams are more than paper things</p>
          <p>And <Ch>D</Ch>it's alright mama you're afraid</p>
          <p><Ch>Em</Ch>I'll be poor along the way</p>
          <p>But <Ch>D</Ch>I don't wanna see those tears again</p>
          <p>You know, <Ch>Em</Ch>Jesus drives an Astrovan<Ch>D</Ch></p>
          <p>Yes, he <Ch>Em</Ch>does (I say<Ch>D</Ch> woo)<Ch>Em</Ch></p>
        </Chorus>

        <Verse>
          <p>And <Ch>D</Ch>in my heart there's a holy ghost <Ch>Em</Ch>writhing on the floor from an overdose</p>
          <p>You know the <Ch>D</Ch>best ones never come <Ch>Em</Ch>down</p>
          <p>So if <Ch>D</Ch>I love at the tip of my toes <Ch>Em</Ch>reaching out for the great unknown</p>
          <p>Every <Ch>D</Ch>addict has ill<Ch>Em</Ch>usions</p>
        </Verse>

        <Chorus>
          <p>He said <Ch>D</Ch>life ain't ever what it seems</p>
          <p>These <Ch>Em</Ch>dreams are more than paper things</p>
          <p>And <Ch>D</Ch>it's alright mama you're afraid</p>
          <p><Ch>Em</Ch>I'll be poor along the way</p>
          <p>But <Ch>D</Ch>I don't wanna see those tears again</p>
          <p>You know, <Ch>Em</Ch>Jesus drives an Astrovan<Ch>D</Ch></p>
          <p>Yes, he <Ch>Em</Ch>does (I say<Ch>D</Ch> woo)<Ch>Em</Ch></p>
          <p><Ch>D</Ch>(it's <Ch>Em</Ch>true... <Ch>D</Ch>yeah)<Ch>Em</Ch></p>
        </Chorus>
        
        <Bridge>
          <p>And <Ch>D</Ch>when I see those angels on the <Ch>G</Ch>roof</p>
          <p>I know I've made it when my</p>
          <p><Ch>D</Ch>Doobie smoking Jesus puts my <Ch>G</Ch>name up on his guest list</p>
          <p>He said son you're <Ch>D</Ch>famous in heaven</p>
          <p>Maybe you're <Ch>G</Ch>famous in heaven</p>
          <p>Maybe there <Ch>D</Ch>is no heaven</p>
          <p>Maybe we're <Ch>Em</Ch>all along together now</p>
          <p>And <Ch>D</Ch>I don't wanna see those tears again</p>
          <p>You know <Ch>Em</Ch>Jesus drives an Astrovan<Ch>D</Ch></p>
          <p><b>Em</b> <b>D</b></p>
          <p><b>Em</b> <b>D</b></p>
          <p><b>Em</b> <b>D</b></p>
        </Bridge>

        <Chorus>
          <p>And <Ch>D</Ch>I don't wanna see those tears again</p>
          <p>You know <Ch>Em</Ch>Jesus drives an Astrovan</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
